input[type="text"],
textarea {
  min-width: 300px !important;
}

.ra-input {
  .ProseMirror {
    min-height: 300px !important;
  }
}
a {
  text-decoration: none !important;
}
.awsImageField--size-tiny {
  width: 40px;
  height: 40px;
  overflow: hidden;
  &.circle {
    border-radius: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.awsImageField--array {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  .awsImageField--size-tiny {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.awsImageField--array-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 400px;
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}

.AWSImageInput {
  margin: 10px 0;
  width: 320px;
  padding: 20px;

  .inner {
    position: relative;
    width: 300px;
    overflow: hidden !important;

    .cover {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}

.AWSThumbnailInput {
  margin: 10px 0;
  width: 640px;
  padding: 20px;

  .inner {
    position: relative;
    width: 620px;
    overflow: hidden !important;

    .cover {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}

.awsImageField--size-tiny {
  width: 40px;
  height: 40px;
  overflow: hidden;
  &.circle {
    border-radius: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.awsThumbnailField--size-small {
  width: 320px;
  height: 180px;
  overflow: hidden;
  &.circle {
    border-radius: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.awsThumbnailField--size-tiny {
  width: 100px;
  height: 56px;
  overflow: hidden;
  &.circle {
    border-radius: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.dropzone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 200px;
  border: 2px dashed #e1e1e1;
  margin: 10px auto;
  color: #a1a1a1;
}

.list-page {
  .actions {
    alignself: "center" !important;
  }
}

.CardWithIcon__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px;

  .content {
    margin-right: 20px;
  }
  .iconContainer {
    padding: 20px;
    background: rgba(220, 36, 64, 0.1);
    .icon {
      color: rgb(220, 36, 64);
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 10px;
    background: #e1e1e1;
    border-radius: 30px;
  }
}

.user-avatar-container {
  display: flex;
  align-items: center;

  .avatar-name {
    margin-left: 10px;
    font-size: 12px;
  }
}

.users-group {
  display: flex;
  align-items: center;
  justify-content: center;
  .avatars {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;

    .avatar {
      margin-left: -20px;
      border: 2px solid #e1e1e1;
    }

    .countCoutainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;

      p {
        font-size: 10px;
        text-align: center;
      }
    }
  }
}

.RaNotification-error {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}
